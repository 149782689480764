import React from 'react';
import less from './404.less';

function gotoHomePage() {
  window.location.href = '/';
}

class Custom404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 5,
      btnContent: '5s后返回首页',
    };
  }

  render() {
    let timeChange;
    let interchange = this.state.time;

    const clock = () => {
      if (interchange > 0) {
        interchange -= 1;
        this.setState({
          time: interchange,
          btnContent: interchange + 's后返回首页',
        });
      } else {
        clearInterval(timeChange);
      }
    };

    timeChange = setInterval(clock, 1000);
    if (this.state.time == 0) {
      window.location.href = '/';
    }

    return (
      <div className={less.container}>
        <div className={less.top}>
          <img src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/404.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192370805&Signature=GFuvQlDPJBDdk9b39x0CGeJHdqQ%3D" alt="404logo" />
        </div>
        <div className={less.middle}>
          <span>
          找不到访问的页面，系统将自动返回首页
          </span>
        </div>
        <div className={less.bottomButton}>
          <button className={less.button} type="button" onClick={gotoHomePage}>{ this.state.btnContent }</button>
        </div>
      </div>
    );
  }
}

export default Custom404;
